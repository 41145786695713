<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="clearfix">
          <button
            type="button"
            class="btn btn-primary float-xl-right"
            @click="navToNewChecklist"
          >
            New Checklist
          </button>
        </div>

        <CDataTable
          :items="items"
          :fields="fields"
          striped
          clickable-rows
          column-filter
          table-filter
          items-per-page-select
          :items-per-page="20"
          sorter
          ref="vuetable"
          pagination
          :responsive="false"
        >
          <template #index="data">
            <td>
              {{ data.index + 1 }}
            </td>
          </template>

          <template #createdTime="{ item }">
            <td class="font-weight-bold" v-if="item.createdTime">
              {{
                item.createdTime
                  | dateParse()
                  | dateFormat("MMM D, YYYY hh:mm A")
              }}
            </td>
            <td v-else>--</td>
          </template>

          <template #show_details="{ item }">
            <td class="py-2">
              <CDropdown togglerText="Actions" color="info">
                <CDropdownItem
                  :to="{
                    name: 'Tasks',
                    params: {
                      checklistId: item.checklistId,
                      modelId: item.modelId,
                      mode: 'view',
                    },
                  }"
                  >Tasks</CDropdownItem
                >
                <CDropdownItem
                  :to="{
                    name: 'Checklist',
                    params: { checklistId: item.checklistId, mode: 'view' },
                  }"
                  >View</CDropdownItem
                >

                <ConfirmationModal
                  :parent="$refs.modalArea"
                  :description="'Are you sure to Delete'"
                  @on:ok="deleteCheckList(item.checklistId)"
                ></ConfirmationModal>
              </CDropdown>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <div ref="modalArea"></div>
      <CCardFooter align="right">
        <CButton
          class="mr-2"
          type="Cancel"
          size="sm"
          color="danger"
          @click="cancel()"
        >
          <CIcon name="cil-ban" /> Cancel
        </CButton>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
const fields = [
  "index",
  {
    key: "checkListName",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },

  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
import ConfirmationModal from "../../containers/ConfirmationModal";

export default {
  name: "Checklists",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getChecklist() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/eChecklists/" +
          this.$route.params.modelId
      )
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
    isTaskMandatory() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/eChecklists/" +
          this.$route.params.modelId
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("task mandatory", data);

          window.location.reload();
        });
    },

    deleteCheckList(checklistId) {
      console.log("delete called with", checklistId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/checklist/" +
          checklistId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getChecklist();
          this.$nextTick(() => {
            this.$refs.vuetable.refresh();
          });
          window.location.reload();
        });
    },
    // deleteCheckList(checklistId) {
    //   console.log("delete called with", checklistId);
    //  this.apiGetSecure(
    //     process.env.VUE_APP_API_HOST +
    //       "​/emapp​/web​/secure​/delete​/checklist/" +
    //      checklistId
    //   )
    //     .then((response) => response.json())
    //     .then((data) => {
    //       this.getChecklist();
    //          this.$nextTick(() => {
    //          this.$refs.vuetable.refresh();
    //        });
    //        window.location.reload();
    //     });
    // },
    navToNewChecklist() {
      this.$router.push({
        name: "Checklist",
        params: { modelId: this.$route.params.modelId, mode: "new" },
      });
    },
    cancel() {
      //  this.$router.push({ name: 'Sub Groups' })
      this.$router.push({
        name: "Models",
        params: {
          subGroupId: this.$route.params.subGroupId,
          groupId: this.$route.params.groupId,
        },
      });
    },
  },
  mounted() {
    this.getChecklist();
  },
};
</script>